import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User, Token, RefreshToken } from 'src/app/models/auth/user';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from '../customer/customer.service';
import { tap } from 'rxjs/operators';
import { AuthenticationToken, IAuthService, Role, ToasterNotificationService } from 'bas-common';

const swal = require('sweetalert');
const API_URL = `${environment.apiUrl}/Authentication`;

@Injectable({
    providedIn: 'root'
})
export class AuthService implements IAuthService {
    private loggedIn = false;
    private reloadLogo = false;
    private role: Role = null;
    private colorTheme = '#3a3f51';
    logoUrl: string;
    types: string[] = [];
    noLogo = 'assets/images/no-logo.jpg';
    private commerceLogo: any;
    _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.loggedIn);
    _colorTheme: BehaviorSubject<string> = new BehaviorSubject<string>(this.colorTheme);
    private _username: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _rolename: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    _commerceLogo: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _commerceCode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _customerCode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    loggedIn$ = this._loggedIn.asObservable();
    username$ = this._username.asObservable();
    rolename$ = this._rolename.asObservable();
    commerceLogo$ = this._commerceLogo.asObservable();
    commerceCode$ = this._commerceCode.asObservable();
    customerCode$ = this._customerCode.asObservable();
    colorTheme$ = this._colorTheme.asObservable();
    customer: Customer;
    static tokensStorageKey = "tokens";
    static logoStorageKey = "logo";
    static themeStorageKey = "theme";

    get getUsername() {
        return this._username.getValue();
    }

    get geRolename() {
        return this._rolename.getValue();
    }

    get isLoggedIn(): boolean {
        return this.loggedIn;
    }

    getRole() {
        return this.role;
    }

    get getCommerceCode() {
        return this._commerceCode.getValue();
    }

    get getCustomerCode() {
        return this._customerCode.getValue();
    }

    get getColorTheme() {
        return this._colorTheme.getValue();
    }

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private sanitization: DomSanitizer,
        private toaster: ToasterNotificationService,
        private customerService: CustomerService) {

        const tokens = this.getTokens();
        if (tokens && Date.now() / 1000 < this.getExpirationFromToken(tokens.accessToken)) {
            this.loggedIn = true;
            this.reloadLogo = true;
            this.setDataFromToken(tokens.accessToken, this.reloadLogo);
        }
        if (this.loggedIn) {
            router.navigate(['/inicio']);
        }
        this._loggedIn.next(this.loggedIn);
    }

    login(commerceCode: string, username: string, userpassword: string) {

        const login = {
            commerceCode,
            username,
            userpassword
        };

        this.http.post<User>(`${API_URL}`, login)
            .subscribe(user => {
                this.setTokens(user.tokens);
                localStorage.setItem(AuthService.logoStorageKey, user.logo);
                localStorage.setItem(AuthService.themeStorageKey, user.colorTheme);
                this.setDataFromToken(user.tokens.accessToken, this.reloadLogo);
                this.setLogoToUser(user.logo);
                this.setThemeToUser(user.colorTheme);
                this.loggedIn = true;
                this._loggedIn.next(this.loggedIn);
                // redireccionar a inicio si es exitoso el acceso
                if (this.loggedIn) {
                    this.router.navigate(['/inicio']);
                }
                this.toaster.showToast('success', '', `Bienvenido ${username}`);
            });
    }

    logout() {
        swal({
            title: '¿Desea salir?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: 'Salir',
                    value: true,
                    visible: true,
                    className: 'bg-danger',
                    closeModal: true
                },
                cancel: {
                    text: 'Cancelar',
                    value: null,
                    visible: true,
                    closeModal: true
                }
            }
        }).then((isConfirm) => {
            if (isConfirm) {
                this.redirectToLogin();
            }
        });
    }

    refreshToken() {
        const tokens = this.getTokens();
        const refreshToken = {
            accessToken: tokens.accessToken,
            refreshToken:tokens.refreshToken
        };

        return this.http.post<RefreshToken>(`${API_URL}/refreshToken`, refreshToken)
            .pipe(tap((newTokens) => {
                if (tokens && tokens.accessToken) {
                    const tokens = this.getTokens();
                    tokens.accessToken = newTokens.accessToken;
                    tokens.refreshToken = newTokens.refreshToken;
                    this.setTokens(tokens);
                }
            }));
    }

    redirectToLogin() {
        this.loggedIn = false;
        this.reloadLogo = false;
        this._loggedIn.next(this.loggedIn);
        this.clearUser();
        this.cookieService.deleteAll();
        this.router.navigate(['auth/inicio-sesion']);
    }

    getCommerceCodeFromToken(): string {
        const tokens = this.getTokens();
        const token: Token = JSON.parse(window.atob(tokens.accessToken.split('.')[1]));
        return token.commerce_code;
    }

    getCustomerCodeFromToken(): string {
        const tokens = this.getTokens();
        const token: Token = JSON.parse(window.atob(tokens.accessToken.split('.')[1]));
        return token.customer_code;
    }

    private getExpirationFromToken(tokenAsString: string) {
        const token: Token = JSON.parse(window.atob(tokenAsString.split('.')[1]));
        return token.exp;
    }

    private setDataFromToken(tokenAsString: string, reloadLogo: boolean) {
        const token: Token = JSON.parse(window.atob(tokenAsString.split('.')[1]));
        if (token) {
            this.setRoleFromToken(token);
            this.setUserDataFromToken(token);
            if (reloadLogo) {
                this.setStyleFromLocalStorage();
            }
        }
    }

    private setRoleFromToken(token: Token) {
        if (token.role) {
            // para mostrarlo en userblock en sidemenu
            this._rolename.next(token.role);
        }
        this.role = new Role();
        if (Array.isArray(token.Function)) {
            token.Function.forEach(claim => {
                this.role.functions.push(claim);
            });
        }
        else if (token.Function) {
            this.role.functions.push(token.Function);
        }
    }

    //Setea el usuario conectado en el observable para mostrar en el layout
    private setUserDataFromToken(token: Token) {
        this._username.next(token.unique_name);
        this._commerceCode.next(token.commerce_code);
        this._customerCode.next(token.customer_code);
    }

    //Setea el usuario conectado en el observable para mostrar en el layout
    private setStyleFromLocalStorage() {
        const localLogo: string = localStorage.getItem(AuthService.logoStorageKey);
        this.setLogoToUser(localLogo);

        const localTheme: string = localStorage.getItem(AuthService.themeStorageKey);
        this.setThemeToUser(localTheme);
    }

    private setLogoToUser(logo: any) {
        if (logo) {
            this.logoUrl = logo;
            this.commerceLogo = this.sanitization.bypassSecurityTrustUrl(`data:image/png;base64, ${this.logoUrl}`);
        } else {
            this.logoUrl = this.noLogo;
            this.commerceLogo = this.noLogo;
        }

        this._commerceLogo.next(this.commerceLogo);
    }


    private setThemeToUser(localTheme: string) {
        if (!localTheme) {
            localTheme = 'default';
        }
        this._colorTheme.next(localTheme);
    }

    public setLogoFromCustomer(codeCommerce: string) {

        if (codeCommerce) {
            this.customerService.getCustomerByCommerceCode(codeCommerce).subscribe(data => {
                this.customer = data;
                if (!this.customer) {
                    this.customer = new Customer();
                }
                this.setLogoToUser(this.customer.logo);
                this.setThemeToUser(this.customer.colorTheme);

            });
        }
    }

    resetPassword(commerceCode: string, email: string) {

        const resetPwd = {
            commerceCode,
            email
        };
        return this.http.post(`${API_URL}/ResetPassword`, resetPwd);
    }
    changePassword(userPassword: string, userPasswordNew: string) {
        const changePwd = {       
            userPassword, 
            userPasswordNew };
        return this.http.post(`${API_URL}/ChangePassword`, changePwd);
    }

    changeStyle(customer: Customer) {
        if (customer.logo) {
            localStorage.removeItem(AuthService.logoStorageKey);
            localStorage.setItem(AuthService.logoStorageKey, customer.logo);
            this.logoUrl = customer.logo;
            this.commerceLogo = this.sanitization.bypassSecurityTrustUrl(`data:image/png;base64, ${this.logoUrl}`);
            this._commerceLogo.next(this.commerceLogo);
        }
        if (customer.colorTheme) {
            localStorage.removeItem(AuthService.themeStorageKey);
            localStorage.setItem(AuthService.themeStorageKey, customer.colorTheme);
            // actualiza el observable para que el layout cambie el color
            this._colorTheme.next(customer.colorTheme);
        }
    } 

    clearUser() {
        localStorage.removeItem(AuthService.tokensStorageKey);
        localStorage.removeItem(AuthService.logoStorageKey);
        localStorage.removeItem(AuthService.themeStorageKey);
    }

    getTokens(): AuthenticationToken {
        let result = new AuthenticationToken();
        const currentTokens = localStorage.getItem(AuthService.tokensStorageKey);
        if (currentTokens) {
            Object.assign(result, JSON.parse(currentTokens));
        } else {
            result = null;
        }
        return result;
    }

    setTokens(tokens: AuthenticationToken) {
        let newTokens = new AuthenticationToken();
        if (tokens) {
           newTokens.accessToken = tokens.accessToken;
           newTokens.refreshToken = tokens.refreshToken;

        } else {
            newTokens = null;
        }
        localStorage.setItem(AuthService.tokensStorageKey, JSON.stringify(newTokens));
    }
}

import { PageTitleConfig } from "bas-common";

export const PAGE_TITLES: PageTitleConfig = {
    // Auth
    // '/inicio-sesion': { title: 'Inicio de Sesión' },
    '/auth/inicio-sesion': { title: 'Web de Clientes' },
    '/auth/registro': { title: 'Registro' },
    '/auth/recuperar-clave': { title: 'Web de Clientes' },
    '/auth/confirmar-correo': { title: 'Confirmación de Correo Electrónico' },
    // Inicio
    '/inicio': { title: 'Inicio' },
    // Verificaciones
    '/verificar': {
        title: 'Verificación de Persona',
        // description: 'Busque sus comercios afiliados según los filtros seleccionados.'
    },
    // Mis Planes
    '/planes': {
        title: 'Plan Actual',
        // description: 'Consulte y edite la información básica de su cuenta.'
    },
    // Mis Configuration
    '/configuration': {
        title: 'Configuración',
        // description: 'Consulte y edite la información básica de su cuenta.'
    },
    // Mis Usuarios
    '/usuarios': {
        title: 'Mis Usuarios',
        // description: 'Consulte y edite la información básica de su cuenta.'
    },
    '/usuarios/agregar/0?create=true' : { 
        title: 'Agregar Usuario'
    },
    '/usuarios/editar' : { 
        title: 'Modificar Usuario'
    },
    // Mis Usuarios
    '/credenciales': {
        title: 'Credenciales del Servicio'
        // description: 'Consulte y edite la información básica de su cuenta.'
    },
    // Mis Configuration
    '/cambiar-clave': {
        title: 'Cambio de Contraseña',
        // description: 'Consulte y edite la información básica de su cuenta.'
    },
    // Mis Configuration
    '/configuracion': {
        title: 'Look & Feel',
        // description: 'Consulte y edite la información básica de su cuenta.'
    },
};
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { delay, skipWhile } from 'rxjs/operators';
import { BodyOutputType, ToasterConfig, ToasterService } from 'angular2-toaster';
import { Router, NavigationEnd } from '@angular/router';
import { AppContextService, HeaderService, ToasterNotificationService } from 'bas-common';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    loggedIn: boolean;
    colorTheme: string;
    showLoader: boolean;
    showFooter: boolean;
    title: string;
    description: string;
    // TOASTER
    toaster: any;
    toasterconfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-center',
        showCloseButton: true,
        preventDuplicates: false,
        bodyOutputType: BodyOutputType.TrustedHtml
    });

    constructor(
        private auth: AuthService,
        private appContext: AppContextService,
        private toasterService: ToasterService,
        private header: HeaderService,
        private toasterNotification: ToasterNotificationService,
        private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd
                && (event.url.startsWith('/inicio')
                    || event.url.startsWith('/auth/inicio-sesion') )) {
                this.showFooter = true;
            } else {
                this.showFooter = false;
            }
        });
    }

    ngOnInit() {
        // actualiza el color del customer
        this.auth.colorTheme$.subscribe(data => {
            this.colorTheme = data;
            if (!this.colorTheme)
            {
                this.colorTheme = '#3a3f51';
            }
            document.documentElement.style.setProperty('--primary', this.colorTheme); 
            document.documentElement.style.setProperty('--secondary', this.colorTheme); 
        });
        
        this.auth.loggedIn$.subscribe(data => {
            this.loggedIn = data;
        });
       
        this.appContext.asyncCall$.pipe(
            // ValueChangedAfterDetection Error Fix
            delay(0)
        )
            .subscribe(data => {
                this.showLoader = data;
            });

        this.toasterNotification.toaster$
            .pipe(
                skipWhile(t => t == null || t === undefined)
            )
            .subscribe(toast => {
                this.toaster = toast;
                this.pop();
            });

        this.header.title.subscribe(title => {
            if (title !== undefined) {
                this.title = title.title;
                this.description = title.description;
            }
        });
    }

    pop() {
        this.toasterService.pop(this.toaster.type, this.toaster.title, this.toaster.text);
    }

}

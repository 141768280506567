import { LayoutComponent } from '../modules/layout/layout.component';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { 
                path: 'auth', loadChildren: () => import('../modules/auth/auth.module').then(mod => mod.AuthModule) },

            {
                path: 'inicio', loadChildren: () => import('../modules/home/home.module').then(mod => mod.HomeModule)
            },
            {
                path: 'verificar', loadChildren: () => import('../modules/verify/verify.module').then(mod => mod.VerifyModule)
            },
            // Usuarios
            {
                path: 'usuarios', loadChildren: () => import('../modules/user/user.module').then(mod => mod.UserModule)
            },
            //Reportes Dinámicos
            {
                path: 'reportes/:name', loadChildren: () => import('./wrappers/dynamic-reports-wrapper.module').then(mod => mod.DynamicReportsWrapperModule)
            },
            // Configuracion
            {
                path: 'configuracion', loadChildren: () => import('../modules/configuration/configuration.module').then(mod => mod.ConfigurationModule)
            },     
            // planes
            {
                path: 'planes', loadChildren: () => import('../modules/plan/plan.module').then(mod => mod.PlanModule)
             },    
            // Cuenta
            {
                path: 'cambiar-clave', loadChildren: () => import('../modules/account/account.module').then(mod => mod.AccountModule)
             },    
            // Credenciales
            {
                path: 'credenciales', loadChildren: () => import('../modules/credential/credential.module').then(mod => mod.CredentialModule)
             },
            // default
            { path: '', redirectTo: 'inicio', pathMatch: 'full' },
        ]
    },

    // Not found
    { path: '**', redirectTo: 'inicio' }

];
